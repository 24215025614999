import React from 'react'
import ReactDOM from 'react-dom'

// eslint-disable-next-line no-unused-vars
class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      thisYear: new Date().getFullYear()
    }
  }
  render() {
    const padStyle = {
      paddingLeft: 20
    }
    return (
      <div className="container-fluid">
        <div className="row justify-content-md-center">
          <div className="col-lg-10 col-sm-12">
            <div className="row" style={padStyle}>
              <div className="col">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 footer-content">
                    <div className="row  item-center">
                      <div className="col-lg-6">
                        <p className="logo-text">
                                  精品不止于<span>匠</span>，服务更关乎<span>心</span>
                        </p>
                      </div>
                      <div className="col-lg-6 contact-info">
                        <ul>
                          <li>
                            <i className="iconfont icon-dianhua"></i>  电话：020-84889693
                          </li>
                          <li>
                            <i className="iconfont icon-youxiang"></i>  邮箱：Rt@sinyonet.com
                          </li>
                          <li>
                            <i className="iconfont icon-dizhi"></i>  地址：广东省广州市黄埔大道西76号富力盈隆广场2908
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row copyright"><p>Copyright © 2016- <span>{this.state.thisYear}</span>  sinyonet.com All Rights Reserved 深圳市心云科技有限公司  版权所有 <a href="http://beian.miit.gov.cn" target="_blank">粤ICP备18068365号-1</a></p> </div>
        </div>
      </div>
    )
  }
}
ReactDOM.render(<Footer/>, document.getElementById('foot'))